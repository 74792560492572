<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<Header id="header" :title="'TRITON 1.5.3.2'" />
        <img src="https://bilder.dein-newsletter.com/sc/triton/tpng-logo_rgb.png" id="tpng_logo">
			</div>
		</div>
	</div>
</template>
<script>
import Header from "../components/Header.vue";
export default {
	components: {
		Header
	}
};

</script>
<style lang="scss" scoped>
/* stylelint-disable indentation */

#tpng_logo  {
  margin: 30px;
  width: 50%;
}


</style>
